<template>
  <div class="contentmain">
    <div class="header-box">
      <img class="logopic" src="../../assets/logo.png" />
      <div class="tb-one">
        <div class="">
          <el-date-picker
            v-model="topXiangmutime"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="|"
            start-placeholder="项目开始时间"
            end-placeholder="项目开始时间"
            :picker-options="pickerOptions"
            style="width: 360px; margin-right: 5px"
          ></el-date-picker>
        </div>
        <div class="">
          <el-select
            :value="topSkillNames.length > 0 ? topSkillNames.join(',') : ''"
            placeholder="请选择职能"
            @click.native="choosejineg()"
            style="width: 240px; margin-right: 5px"
          >
            <!-- <el-option label="Java" value="1"></el-option>
						<el-option label="PHP测试" value="2"></el-option>
						<el-option label="安卓开发工程师" value="3"></el-option> -->
          </el-select>
        </div>
        <div class="tb-two">
          <el-input
            placeholder="可输入关键字来搜索企业和职位，如企业名称、技能标签等"
            prefix-icon="el-icon-search"
            v-model="topPageInfo.keyword"
            style="width: 340px; margin-right: 5px"
          >
          </el-input>
        </div>
        <div class="search-btn">
          <el-button class="" type="primary" @click="toresult">搜索</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Bus from "../../common/bus.js";
export default {
  props: ["pageInfo", "xiangmutime", "skillNames", "skillIds"],
  data() {
    return {
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      topPageInfo: {},
      topXiangmutime: "",
      topSkillNames: [],
      topSkillIds: [],
    };
  },
  watch: {
    topXiangmutime(value) {
      this.$emit("timeChange", value);
    },
    xiangmutime(value) {
      this.topXiangmutime = value;
    },
  },
  created() {
    this.topPageInfo = this.pageInfo;
    this.topXiangmutime = this.xiangmutime;
    // console.log("this.skillNames===", this.skillNames);
    this.topSkillNames =
      this.skillNames instanceof Array && this.skillNames.length > 0
        ? this.skillNames.split(",")
        : "";
    this.topSkillIds = this.skillIds;
  },

  methods: {
    choosejineg() {
      //选择技能
      var that = this;
      Bus.$emit("jinengstuts", {
        show: true,
        model: 1,
        callback: (arr) => {
          arr.map((item) => {
            if (that.topSkillIds.indexOf(item.value) == -1) {
              that.topSkillNames.push(item.label);
              that.topSkillIds.push(item.value);
            }
          });
          that.topPageInfo.skillIds = that.topSkillIds.join(",");
        },
      });
    },
    toresult() {
      //搜索
      if (this.topXiangmutime) {
        this.topPageInfo.start = this.topXiangmutime[0];
        this.topPageInfo.end = this.topXiangmutime[1];
      }
      this.$router.push("/serachresult");
      let searchJobConfig = {
        selectEducations: [],
        pageInfo: this.topPageInfo,
      };
      sessionStorage.setItem(
        "searchJobConfig",
        JSON.stringify(searchJobConfig)
      );
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-input__inner {
  height: 55px;
  border-radius: 6px;
}
/deep/.el-range-separator {
  color: #dcdcdc;
  display: flex;
  align-items: center;
  justify-content: center;
}
.header-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 88px;
  // background-color: #000080;
  transform: translate(-9px, 0px);
  .logopic {
    width: 46px;
    height: 46px;
    margin-right: 45px;
  }
  .tb-one {
    height: 54px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex: 1;
    .tb-two {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .search-btn {
      /deep/ .el-button {
        width: 133px;
        height: 54px;
        border-radius: 6px;
        background-color: #00bcff;
        border: none;
      }
    }
  }
}
</style>
