<template>
  <div class="content" ref="pronbit">
    <div class="topbian" v-if="headerstuts">
      <hidetop
        :pageInfo="pageInfo"
        :xiangmutime="xiangmutime"
        :skillNames="skillNames"
        :skillIds="skillIds"
        @timeChange="
          (value) => {
            xiangmutime = value;
          }
        "
      ></hidetop>
    </div>
    <div class="banner">
      <el-carousel height="400px" style="z-index: 0">
        <el-carousel-item v-for="item in imagesBox" :key="item.id">
          <img
            :src="'https://freemen.work/user/file/view/' + item.imageUrl"
            @click="imgClick(item.linkUrl)"
          />
        </el-carousel-item>
      </el-carousel>
      <!-- <img src="https://img.zcool.cn/community/019ea25981301a0000002129c4864c.jpg@1280w_1l_2o_100sh.jpg" /> -->
    </div>
    <div class="contentmain">
      <div class="tabbox">
        <el-card class="box-cardtb">
          <div class="tablist font18">
            <!-- @mouseover="mouseOver(item.id)" @mouseleave="mouseLeave(item.id)" -->
            <div
              class="t-main"
              :class="activenum == item.id ? 'activeclass' : ''"
              @click="activenum = item.id"
              v-for="(item, index) in tablist"
              :key="index"
            >
              <img :src="activenum == item.id ? item.icon1 : item.icon2" />
              <span class="">{{ item.name }}</span>
            </div>
          </div>
        </el-card>
        <div class="tabcardbox">
          <div class="tb-one">
            <div class="" v-if="activenum == 1">
              <el-date-picker
                v-model="xiangmutime"
                type="daterange"
                align="right"
                unlink-panels
                range-separator="|"
                start-placeholder="项目开始时间"
                end-placeholder="项目开始时间"
                :picker-options="pickerOptions"
                style="width: 410px; margin-right: 20px"
              ></el-date-picker>
            </div>
            <div class="tidai" v-else-if="activenum == 2">
              <img src="../../assets/images/pagehome/tabtidai.png" />
            </div>
            <div class="">
              <!-- <el-select
								:value="skillNames.join(',')"
								placeholder="请选择职能"
								style="width: 410px;"
								@click.native="choosejineg()">
								<el-option label="Java" value="1"></el-option>
							</el-select> -->
              <el-select
                v-model="skillId"
                placeholder="请选择职能"
                style="width: 410px"
              >
                <el-option
                  v-for="item2 in arr"
                  :key="item2.value"
                  :label="item2.label"
                  :value="item2.value"
                ></el-option>
              </el-select>
            </div>
          </div>
          <div class="tb-two">
            <el-input
              placeholder="可输入关键字来搜索企业和职位，如企业名称、技能标签等"
              prefix-icon="el-icon-search"
              v-model="pageInfo.keyword"
              style="width: 840px"
            >
            </el-input>
          </div>

          <div class="search-btn">
            <el-button class="" type="primary" @click="toresult()"
              >搜索</el-button
            >
          </div>
        </div>
      </div>
      <!-- 三个小banner图 -->
      <div class="threetu">
        <!-- animate__animated -->
        <!-- @mouseover="changeActive($event)" @mouseleave="removeActive($event)" -->
        <div class="threetu-left" v-if="imagesBox2.length > 0">
          <img
            @click="imgClick(imagesBox2[0].linkUrl)"
            :src="
              'https://freemen.work/user/file/view/' + imagesBox2[0].imageUrl
            "
          />
        </div>
        <div class="threetu-right" v-if="imagesBox2.length > 1">
          <template
            v-for="(i, index) in imagesBox2"
            @click="imgClick(i.linkUrl)"
          >
            <img
              :key="index"
              :src="'https://freemen.work/user/file/view/' + i.imageUrl"
              v-if="index > 0"
            />
          </template>
        </div>
      </div>
      <div class="biaotilan">
        <div class="zhu-tit font26">推荐职位</div>
        <div class="heng">-</div>
        <!-- <div class="fu-tit font14" v-if="tn==false">登录后可根据求职意向推荐哦</div> -->
        <div class="fu-tit font14" v-if="tn && !showRecommendTab">
          您已在职无法查看握手信息
        </div>
      </div>
      <!-- 推荐职位 -->
      <div class="tuijian">
        <el-card class="position-choose font16">
          <div class="p-left" v-if="!showRecommendTab">
            <div
              class="pc-main"
              :class="jobConfig.userJobId == itemes.id ? 'dianji' : ''"
              v-for="(itemes, index2) in wantlist"
              :key="index2"
              @click="choosetj(itemes.id)"
            >
              <span>{{ itemes.jobName }}</span>
            </div>
          </div>
          <div class="p-left" v-else>
            <div
              class="pc-main"
              :class="tab == 1 ? 'dianji' : ''"
              @click="getRecommendJobList"
            >
              <span>热门职位</span>
            </div>
            <div
              class="pc-main"
              :class="tab == 2 ? 'dianji' : ''"
              @click="getNewJobList"
            >
              <span>最新职位</span>
            </div>
          </div>
          <div class="p-right" @click="toposition()">
            <span>更多推荐职位</span>
            <i class="el-icon-arrow-right"></i>
          </div>
        </el-card>
        <el-row>
          <el-col
            style="margin-top: 10px"
            :span="8"
            v-for="(itemli, index3) in jobList"
            :key="index3"
          >
            <el-card class="tjli font14">
              <div class="l-one font16" @click="todetail(itemli.id)">
                <div class="">
                  {{ itemli.jobName }}
                  <span v-if="itemli.jobType == 1">Free</span>
                </div>
                <div class="">
                  {{ (itemli.salaryLow / 1000) | numFilter }}K-{{ (itemli.salaryHigh / 1000) | numFilter }}K
                </div>
              </div>
              <el-divider v-if="itemli.jobType !== 1"></el-divider>
              <!-- <div class="l-two">{{itemli.educationType.length ? itemli.educationType[0].itemText : '/'}}</div> -->
              <div class="l-three" v-if="itemli.jobType == 1">
                项目周期：
                {{ itemli.startDate ? itemli.startDate.substring(0, 11) : "/" }}
                -
                {{ itemli.endDate ? itemli.endDate.substring(0, 11) : "/" }}
              </div>
              <div
                class="l-three"
                v-if="itemli.jobType != 1"
                style="opacity: 0"
              >
                项目周期：
              </div>
              <el-divider v-if="itemli.jobType === 1"></el-divider>
              <div class="l-four" @click="todetail(itemli.id)">
                <div class="">
                  <img
                    v-if="itemli.companyAvatar"
                    :src="
                      'https://freemen.work/user/file/view/' + itemli.companyAvatar
                    "
                    @error="setDefaultImage"
                  />
                  <img v-else src="../../assets/logo.png" />
                  <span>{{ itemli.shortName }}</span>
                </div>
                <div class="">
                  剩余招聘:
                  <span>{{ itemli.jobFree }}</span>
                </div>
              </div>
            </el-card>
          </el-col>
        </el-row>
        <!-- 无记录 -->
        <div class="nodata" v-if="!jobList.length">
          <img src="../../assets/images/common/search-no.png" />
          <div class="font18" v-if="!showRecommendTab && userData != null">
            {{
              userData.workStatus == 1
                ? "您已在职无法查看握手信息"
                : "暂无匹配的职位"
            }}
          </div>
          <div class="font18" v-else>暂无数据</div>
        </div>
      </div>
      <div class="two-banner">
        <img
          :style="
            index == 0 ? 'margin-right: 20px;width:580px ' : 'width:580px '
          "
          :src="'https://freemen.work/user/file/view/' + i.imageUrl"
          v-for="(i, index) in imagesBox3"
          :key="index"
          @click="imgClick(i.linkUrl)"
        />
      </div>
      <div class="biaotilan">
        <div class="zhu-tit font26">热门企业</div>
        <div class="heng">-</div>
        <div class="fu-tit font14" v-if="hotbusiness.length > 0">根据求职意向推荐</div>
        <div class="tonore" @click="toqiye()">
          <span>更多推荐企业</span>
          <i class="el-icon-arrow-right"></i>
        </div>
      </div>

      <div class="enterprise">
        <el-row>
          <el-col
            style="margin-top: 20px"
            :span="6"
            v-for="(itemqy, index4) in hotbusiness"
            :key="index4"
          >
            <el-card class="qiye">
              <div class="qiye-main" @click="todetail1(itemqy.id)">
                <img
                  style="border-radius: 10px"
                  :src="
                    'https://freemen.work/user/file/view/' +
                    itemqy.companyAvatar
                  "
                  @error="setDefaultImage"
                  v-if="itemqy.companyAvatar"
                />
                <img
                  style="border-radius: 10px"
                  src="../../assets/logo.png"
                  v-else
                />
                <div class="qy-name font18">{{ itemqy.shortName }}</div>
                {{ itemqy.companyType ? itemqy.companyType.itemText : "" }}
                <div class="qy-btn font14">
                  <el-button plain>
                    <span>{{ itemqy.jobCount }}</span>
                    个热招职位
                  </el-button>
                </div>
              </div>
            </el-card>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import hidetop from "./hidetop.vue";
import defaultLostImg from '@/assets/logo.png'
export default {
  components: {
    hidetop,
  },
  data() {
    return {
      skillId: null,
      activenum: 1,
      tablist: [
        {
          id: 1,
          name: "Freemen",
          icon1: require("../../assets/images/pagehome/qiye-bule.png"),
          icon2: require("../../assets/images/pagehome/qiye-grey.png"),
        },
        {
          id: 2,
          name: "入职",
          icon1: require("../../assets/images/pagehome/ruzhi-bule.png"),
          icon2: require("../../assets/images/pagehome/ruzhi-grey.png"),
        },
      ],
      imagesBox: [],
      imagesBox2: [],
      imagesBox3: [],
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      pageInfo: {
        pageNo: 1,
        pageSize: 10,
        city: "",
        userJobId: "",
        skillIds: "",
        start: "",
        end: "",
        salaryLow: "",
        salaryHigh: "",
        educationTypes: "",
        expLow: "",
        expHigh: "",
        keyword: "",
      },
      arr: [],
      xiangmutime: "",
      skillNames: [],
      skillIds: [],
      wantlist: [],
      tn: false,
      jobConfig: {
        pageNo: 1,
        pageSize: 6,
        userJobId: "",
        isHand: 0,
      },
      jobList: [],
      companyList: [],
      hotbusiness: [
        { id: 1, name: "小红书", type: "私企", num: 20 },
        { id: 1, name: "阿里巴巴", type: "国企", num: 20 },
        { id: 1, name: "字节跳动", type: "私企", num: 20 },
        { id: 1, name: "网易", type: "私企", num: 20 },
        { id: 1, name: "微博", type: "国企", num: 20 },
        { id: 1, name: "高德地图", type: "私企", num: 20 },
        { id: 1, name: "华为", type: "国企", num: 20 },
        { id: 1, name: "链家", type: "私企", num: 20 },
      ],
      rollheight: 0,
      headerstuts: false,
      isLogin: false,
      showRecommendTab: false,
      tab: 1,
      pageInfo1: {
        isHot: 1,
        pageNo: 1,
        pageSize: 8,
      },
      userData: null,
    };
  },
  filters: {
    numFilter(value) {
      let transformVal = Number(value).toFixed(2)
      let realVal = transformVal.substring(0, transformVal.length - 1)

      return Number(realVal)
    }
  },
  watch: {
    activenum(value) {
      if (value == 2) {
        this.xiangmutime = "";
      }
    },
  },
  created() {
    let isLogin = sessionStorage.getItem("userInfo");
    this.isLogin = isLogin;
    if (isLogin) {
      this.getWantJob();
      // this.getCompanyList()
      this.getSkil();
    } else {
      this.showRecommendTab = true;
      this.getRecommendJobList();
    }

    this.banner(3);
    this.banner(4);
    this.banner(5);
    this.getList();
    if (isLogin) {
      this.$api.getUserData("get").then((res) => {
        this.userData = res.data;
      });
    }
  },

  // mounted() {
  //   window.addEventListener("scroll", this.handleScrollx, true);
  // },
  // destroyed() {
  //   window.removeEventListener("scroll", this.handleScrollx, true);
  // },
  methods: {
    setDefaultImage(e) {
      e.target.src = defaultLostImg;
    },
    todetail1(id) {
      // this.$router.push({path:'/qiyedetail',query:{id:id,userJobId:this.pageInfo.wantJob}})
      this.$router.push({
        path: "/qiyedetail",
        query: { id: id, userJobId: this.$route.query.userJobId },
      });
    },
    getList() {
      this.$api.getCompanyList("get", this.pageInfo1).then((res) => {
        if (res.code == 10200) {
          this.hotbusiness = res.data;
        }
      });
    },
    imgClick(i) {
      if (i) {
        window.open(i);
      }
    },
    getNewJobList() {
      this.tab = 2;
      //获取推荐职位列表
      this.$api.getNewJob("get", this.jobConfig).then((res) => {
        this.jobList = res.data || [];
      });
    },
    getRecommendJobList() {
      this.tab = 1;
      //获取推荐职位列表
      this.$api.getRecommendJob("get", this.jobConfig).then((res) => {
        this.jobList = res.data || [];
      });
    },
    banner(location) {
      this.$api.banner("get", { location: location }).then((res) => {
        if (location == 3) {
          this.imagesBox = res.data;
        }
        if (location == 4) {
          this.imagesBox2 = res.data;
        }
        if (location == 5) {
          this.imagesBox3 = res.data;
        }
      });
    },
    getSkil() {
      this.$api.getHotSkill("get").then((res) => {
        let arr = [];
        res.data.map((item) => {
          let obj = {
            value: item.id,
            label: item.name,
          };
          arr.push(obj);
        });
        this.arr = arr;
      });
    },
    getWantJob() {
      //获取求职意向
      this.$api.getWantJob("get").then((res) => {
        this.wantlist = res.data;
        if (res.data && res.data.length > 0) {
          this.pageInfo.userJobId = res.data[0].id;
          this.jobConfig.userJobId = res.data[0].id;
          this.getJobList();
        } else {
          this.showRecommendTab = true;
          this.getRecommendJobList();
        }
      });
    },
    getJobList() {
      //获取推荐职位列表
      this.$api.getJobList("get", this.jobConfig).then((res) => {
        this.jobList = res.data || [];
        if (res.code == 10400) {
          this.tn = true;
        }
      });
    },
    getCompanyList() {
      //获取公司列表
      this.$api.searchCompany("get", { keyword: "" }).then((res) => {
        this.companyList = res.data;
      });
    },
    // choosejineg() {
    //   //选择技能
    //   Bus.$emit("jinengstuts", {
    //     show: true,
    //     callback: (arr) => {
    //       arr.map((item) => {
    //         if (this.skillIds.indexOf(item.value) == -1) {
    //           this.skillNames.push(item.label);
    //           this.skillIds.push(item.value);
    //         }
    //       });
    //       this.pageInfo.skillIds = this.skillIds.join(",");
    //     },
    //   });
    // },
    toresult() {
      //搜索
      if (this.xiangmutime) {
        this.pageInfo.start = this.xiangmutime[0];
        this.pageInfo.end = this.xiangmutime[1];
      }
      if (this.skillId) {
        this.pageInfo.skillIds = this.skillId;
      }
      this.$router.push("/serachresult");
      let searchJobConfig = {
        selectEducations: [],
        pageInfo: this.pageInfo,
      };
      sessionStorage.setItem(
        "searchJobConfig",
        JSON.stringify(searchJobConfig)
      );
    },
    handleScrollx() {
      // console.log('滚动高度',window.pageYOffset)
      // console.log('距离顶部高度',this.$refs.pronbit.getBoundingClientRect().top)
      // this.rollheight = this.$refs.pronbit.getBoundingClientRect().top;
      // if (this.rollheight < 0) {
      //   this.headerstuts = true;
      // } else {
      //   this.headerstuts = false;
      // }
    },
    // mouseOver(id){
    // 	this.activenum=id;
    // 	console.log('移入',id)
    // },
    // mouseLeave(){
    // 	console.log('移出')
    // },
    choosetj(num) {
      //选择求职意向
      this.jobConfig.userJobId = num;
      this.pageInfo.userJobId = num;
      this.getJobList();
    },
    todetail(id) {
      this.$router.push({
        path: "/positiondetail",
        query: { id: id, userJobId: this.pageInfo.userJobId },
      });
    },
    toposition() {
      this.$router.push("/positionindex");
    },
    toqiye() {
      this.$router.push("/qiyeindex");
    },
    changeActive($event) {
      $event.currentTarget.className = "yangshi";
    },
    removeActive($event) {
      $event.currentTarget.className = "yangshi2";
    },
  },
};
</script>

<style lang="less" scoped>
.topbian {
  height: 88px;
  width: 100%;
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.2) !important;
}
.content {
  position: relative;
  margin-bottom: 90px;
  // transform: translate(8px, 0px);
}
.banner {
  // position: absolute;
  // width: 100%;
  // top: 0;
  // left: 0;
  // right: 0;
  img {
    width: 100%;
    height: 400px;
  }
}
.tabbox {
  margin-top: -150px;
}
.box-cardtb {
  width: 600px;
  height: 110px;
  z-index: 1;
  margin: 0 auto;
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.2) !important;
  position: relative;
  border-radius: 10px;
  .tablist {
    display: flex;
    align-items: center;
    .t-main:hover {
      cursor: pointer;
      // color: #00bcff;
    }
    .t-main {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      flex: 1;
      color: #4b4f56;
      line-height: 2.3;
      img {
        width: 29px;
        height: 29px;
      }
    }
    .activeclass {
      color: #00bcff;
      position: relative;
    }
    .activeclass :after {
      position: absolute;
      content: "";
      width: 80px;
      height: 3px;
      bottom: -21px;
      background: #00bcff;
      left: 36%;
    }
  }
}
.nodata {
  background-color: #ffffff;
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.2) !important;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 80px 0 130px 0;
  margin-top: 20px;
  div {
    color: #111111;
    margin-top: 20px;
  }

  img {
    width: 260px;
    height: 220px;
  }
}
.tabcardbox {
  border-radius: 10px;
  border: 1px solid #ebeef5;
  width: 1000px;
  height: 220px;
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.2) !important;
  margin: -65px auto 0 auto;
  background-color: #f3f3f3;
  position: relative;
  padding: 100px 0px 0 0px !important;
  /deep/ .el-input__inner {
    height: 55px;
    border-radius: 10px;
  }
  // /deep/.el-card__body {

  // }
  /deep/.el-range-separator {
    color: #dcdcdc;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .tb-one {
    display: flex;
    align-items: center;
    justify-content: center;
    .tidai {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 410px;
      height: 55px;
      border-radius: 10px;
      background-color: #ddebff;
      margin-right: 20px;
      img {
        width: 216px;
        height: 20px;
      }
    }
  }
  .tb-two {
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .search-btn {
    position: absolute;
    bottom: -17%;
    left: 50%;
    transform: translate(-50%, -50%);
    button {
      width: 490px;
      height: 55px;
      font-size: 18px;
      letter-spacing: 1px;
      box-shadow: 0 0 16px rgba(0, 0, 0, 0.2) !important;
    }
  }
}
.threetu {
  display: flex;
  align-items: center;
  margin-top: 100px;
  .threetu-left {
    width: 410px;
    height: 386px;
    margin-right: 20px;

    img {
      width: 100%;
      height: 100%;
      transition: all 0.3s;
    }
    img:hover {
      // transform: translateY(-6px);
      transform: scale(1.02);
      // background-color: rgba(0, 0, 0, 0.001);
    }
  }
  // .threetu-left:hover{
  // 	background-size: 110% 110%;
  // }
  // .threetu-left:hover{
  // 	transform: scale(1.02);
  // 	box-shadow: 0 0 16px rgba(0, 0, 0, 0.1) !important;
  // }

  .threetu-right {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex: 1;
    img {
      width: 100% !important;
      height: 188px !important;
      transition: all 0.3s;
    }
    img:hover {
      // transform: translateY(-6px);
      transform: scale(1.02);
    }
    .xia {
      margin-top: 10px;
    }
  }
}
.biaotilan {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 30px 0;
  line-height: 1.6;
  position: relative;
  .tonore:hover {
    cursor: pointer;
  }
  .tonore {
    position: absolute;
    right: 20px;
    top: 8px;
    span {
      color: #00bcff;
      margin-right: 18px;
    }
    .el-icon-arrow-right {
      font-weight: bolder;
      color: #666666;
    }
  }
  .zhu-tit {
    color: #111111;
  }
  .heng {
    color: #04bdff;
    font-weight: bolder;
  }
  .fu-tit {
    color: #666666;
  }
}
.tuijian {
  /deep/.el-card {
    border-radius: 0px;
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.2) !important;
  }
  .el-col:nth-child(2),
  .el-col:nth-child(5) {
    padding: 0 6px;
  }
  .el-col:nth-child(1),
  .el-col:nth-child(4) {
    padding: 0 12px 0 0;
  }
  .el-col:nth-child(3),
  .el-col:nth-child(6) {
    padding: 0 0 0 12px;
  }
  .position-choose {
    /deep/.el-card__body {
      padding: 0 30px !important;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 50px;
    }

    .p-left {
      display: flex;
      align-items: center;
      .pc-main:hover {
        cursor: pointer;
        color: #00bcff;
      }
      .pc-main {
        margin-right: 40px;
        color: #666666;
      }
      .dianji {
        color: #111111;
        position: relative;
      }
      .dianji :after {
        position: absolute;
        content: "";
        width: 35px;
        height: 3px;
        bottom: -16px;
        background: #00bcff;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
    .p-right:hover {
      cursor: pointer;
    }
    .p-right {
      padding-right: 50px;
      span {
        color: #00bcff;
        margin-right: 18px;
      }
      .el-icon-arrow-right {
        font-weight: bolder;
        color: #666666;
      }
    }
  }
}
.tjli:hover {
  // transform: translateY(-6px);
  cursor: pointer;
  transform: scale(1.03);
  background-color: rgba(0, 0, 0, 0.001);
}
.tjli {
  line-height: 1.8;
  .l-one {
    display: flex;
    align-items: center;
    justify-content: space-between;
    div:first-child {
      color: #111111;
      span {
        background-color: #e9f9ff;
        color: #00bcff;
        padding: 1px 10px;
        margin-left: 10px;
      }
    }
    div:last-child {
      color: #ff8400;
    }
  }
  .l-two {
    color: #666666;
  }
  .l-three {
    color: #666666;
  }
  .el-divider {
    background-color: #dddddd;
  }
  .el-divider--horizontal {
    margin: 8px 0;
  }
  .l-four {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #666666;
    div:first-child {
      display: flex;
      align-items: center;
      img {
        width: 34px;
        height: 34px;
        margin-right: 10px;
        border-radius: 50px;
      }
    }
    div:last-child {
      span {
        color: #111111;
      }
    }
  }
}
.two-banner {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  img {
    display: flex;
    flex: 1;
    height: 160px;
  }
  img:hover {
    // transform: translateY(-6px);
    transform: scale(1.01);
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.1) !important;
  }
}

.enterprise {
  /deep/.el-card {
    border-radius: 0px;
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.2) !important;
  }
  .el-col:nth-child(2),
  .el-col:nth-child(3),
  .el-col:nth-child(6),
  .el-col:nth-child(7) {
    padding: 0 10px;
  }
  .el-col:nth-child(1),
  .el-col:nth-child(5) {
    padding: 0 10px 0 0;
  }
  .el-col:nth-child(4),
  .el-col:nth-child(8) {
    padding: 0 0 0 10px;
  }
  .qiye:hover {
    transform: scale(1.03);
    background-color: rgba(0, 0, 0, 0.001);
    cursor: pointer;
  }
  .qiye {
    /deep/.el-card__body {
      padding: 30px 20px;
    }
    .qiye-main {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      height: 280px;
      // line-height: 2;
      img {
        width: 80px;
        height: 80px;
      }
      .qy-name {
        color: #111111;
        margin-top: 20px;
      }
      .qy-type {
        color: #666666;
        margin-top: 10px;
      }
      .qy-btn {
        margin-top: 30px;
        button {
          height: 36px;
          width: 185px;
          font-size: 14px !important;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 1px solid #e4e8ee;
          color: #666666;
          border-radius: 0;
          span {
            color: #00bcff;
          }
        }
      }
    }
  }
}
</style>
